.payment {
  width: 50%;
  justify-content: center;
  align-items: center;
  margin: auto;
  box-shadow: $box-shadow-1;
  padding: 4rem 0;
  height: auto;
  margin-bottom: 3rem;
  position: relative;
  @include respond(tab-port) {
    width: 100%;
  }
  &__card {
    width: 50%;
    margin: auto;
    @include respond(tab-port) {
      width: 90%;
    }
    & p {
      font-size: 2rem;
      padding: 0.5rem 0;
    }
  }
  &__heading {
    font-size: 3rem;
    padding: 2rem 0;
  }
  &__image {
    width: 100%;
    height: 20rem;
    border: 1px solid #444;
    margin-bottom: 2rem;
  }
  & .close {
    position: absolute;
    right: 3rem;
    top: 3rem;
    color: inherit;
    font-size: 3rem;
    border: none;
    background: none;
    cursor: pointer;
    &:hover {
      color: $color-red;
    }
  }
  &__offline {
    margin-top: 2rem;
    padding: 2rem;
    & p {
      font-size: 1.8rem;
      padding: 2rem 0;
    }
    & ul li {
      font-size: 1.8rem;
    }
  }
}
