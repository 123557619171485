.profile-info {
  padding: 1rem;
  & p {
    font-size: 1.8rem;
  }
}
.update-form {
  & h4 {
    padding: 1rem 0;
    width: 60%;
    @include respond(tab-port) {
      width: 100%;
    }
  }
  & input {
    border: 1px solid #ccc;
    border-radius: 1rem;
  }
}
