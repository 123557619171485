.checkout {
  & .cart-item {
    color: $color-white;
    margin: 2rem 0;
    & ul li {
      font-size: 2.5rem;
      // margin-left: 2rem;
      & .icon {
        color: $color-white;
        font-size: 3rem;
      }
    }
    & .price {
      font-size: 3rem;
      padding: 1rem 0;
      & span:first-of-type {
        color: red;
        text-decoration: line-through;
        margin-right: 1rem;
      }
      & span:last-of-type {
        display: flex;
        align-items: center;
        & .icon {
          font-weight: bold;
          color: $color-primary;
          font-size: 5rem;
        }
      }
    }
  }
  & button {
    height: 4rem;
    background: $color-primary;
    color: $color-white;
    font-weight: bold;
    padding: 1rem 4rem;
    border-radius: 1rem;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    &:hover {
      background: $color-success;
    }
  }
}
