.cat-notfound {
  & .step {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    & p {
      padding: 2rem 0;
    }
  }
}
