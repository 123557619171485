@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@mixin cta {
  font-size: 1.5rem;
  font-weight: bold;
  border-radius: 2rem;
  border: 2px solid $color-primary;
  padding: 1rem 2rem;
  margin-top: 1rem;
  text-decoration: none;
  text-transform: uppercase;
  color: $color-primary !important;
  background: $color-white;
  cursor: pointer;
  &:hover {
    border: 2px solid $color-orange;
    color: orange !important;
    text-decoration: none;
  }
}

// MEDIA QUERY MANAGER
/*
0-600       Phone
600-900     Tablet portrait 
900-1200    Tablet landscape
(1200-1800   Normal styles apply)
1800 +      Big Dektop 
 
$breakpoint argument choices:
-phone 
-tab-port
-tab-land
-big-desktop
*/

// ORDER: Base + typography > general layout + grid > page layout > components

@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media only screen and (max-width: 37.5em) {
      @content;
    } //600px
  }
  @if $breakpoint == tab-port {
    @media only screen and (max-width: 56.25em) {
      @content;
    } //900px
  }
  @if $breakpoint == tab-land {
    @media only screen and (max-width: 75em) {
      @content;
    } //1200px
  }
  @if $breakpoint == big-desktop {
    @media only screen and (min-width: 112.5em) {
      @content;
    } //1800px
  }
}
