.close {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 1rem;
  & .icon {
    display: flex;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    // background: $color-red-brand;
    color: $color-white;
    font-size: 2.5rem;
    cursor: pointer;
    &:hover {
      color: $color-red;
    }
  }
}
.cta {
  @include cta;
  cursor: pointer;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-top: 0;
  background: rgba(0, 0, 0, 0.9);
  // background: #010516;
  display: grid;
  place-items: center;
  // transition: var(--transition);
  visibility: hidden;
  z-index: 1000;
}

.result-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-top: 0;
  background: #010516;
  display: grid;
  place-items: center;
  transition: transform 300ms ease-out;
  visibility: visible;
  z-index: 1000;
}
/* OPEN/CLOSE MODAL */
.show-modal {
  visibility: visible;
  z-index: 10;
}
