.navbar {
  display: flex;
  justify-content: space-between;
  height: 8rem;
  padding: 0 10vw;
  background: $color-blue-dark;
  @include respond(tab-port) {
    padding: 0 5vw;
  }

  &-logo {
    display: flex;
    width: 15%;
    justify-content: flex-start;
    align-items: center;
    @include respond(tab-port) {
      width: 50%;
    }
    & a {
      display: flex;
      flex-direction: column;
      & img {
        width: 15rem;
        height: 2.5rem;
        cursor: pointer;
      }
    }
  }
  &-search {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include respond(tab-port) {
      display: block;
      width: 95%;
      position: absolute;
      top: 10rem;
      left: 3vw;
      right: 3vw;
      flex: 1;
      padding-bottom: 2.5rem;
    }
  }
  &-auth {
    display: flex;
    width: 15%;
    justify-content: flex-end;
    align-items: center;

    &__desktop {
      & a {
        padding: 0.5rem;
        border: 3px solid #ccc;
        color: $color-white;
        border-radius: 1rem;
        font-size: 1.5rem;
        text-decoration: none;
        &:hover {
          border: 3px solid $color-white;
        }
      }
      @include respond(tab-port) {
        display: none;
      }
    }
    &__mobile {
      display: none;
      border: none;
      @include respond(tab-port) {
        display: flex;
        cursor: pointer;
        color: $color-white;
      }
      & .menu-icon {
        color: $color-white;
        font-size: 3rem;
      }
    }
  }
}
// styles signin and signup pages
.acc {
  justify-content: center;
  align-items: center;
  background: $color-bg-main-2;
  & a {
    color: $color-white;
    font-size: 3rem;
    text-decoration: none;
    font-weight: bold;
  }
}
