.container {
  display: flex;
  flex-direction: column;
  & .question-counter {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    background: #f2f2f2;
    color: $color-black;
    font-weight: bold;
    margin-bottom: 2rem;
  }
  & .question-navigator {
    width: 100%;
  }
  & .preview {
    padding: 2rem 0;
    &__txt {
      h3 {
        font-size: 1.5rem;
        // line-height: normal;
      }
    }
    &__inputs {
      padding: 1rem 0;
      &-items {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 1.5rem;
        border-bottom: 1px solid $color-primary;
        padding-top: 1rem;
        & div:first-of-type {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 3rem;
          height: 3rem;
          margin-right: 1rem;
          border-radius: 50%;
          background: $color-primary;
          color: $color-white;
        }
        & div:last-of-type {
          flex: 2;
        }
        & .value {
          display: flex;
          justify-content: space-between;
          & span,
          .edit {
            width: 45%;
          }
          & .edit {
            text-align: right;
            background: none;
            border: none;
            & .icon {
              cursor: pointer;
              color: $color-primary;
              font-size: 2rem;
            }
          }
        }
        .text {
          font-weight: bold;
        }
      }
    }
  }
}
