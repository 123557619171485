.userLinks {
  padding: 2rem 0;
  align-self: flex-start;
  & ul {
    display: flex;
    flex-direction: column;
    & li {
      display: flex;
      align-items: center;
      font-size: 2rem;
      padding: 1rem 0;
      & a {
        color: $color-grey-dark-3;
        cursor: pointer;
        text-decoration: none;
      }
      & .icon {
        margin-right: 1rem;
      }
      & button {
        cursor: pointer;
        background: none;
        font-size: inherit;
        border: none;
      }
    }
  }
}
