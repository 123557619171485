@font-face {
  font-family: "Millik";
  src: local("Millik"), url("../../../fonts/Millik.otf") format("opentype");
  font-weight: bold;
}
body {
  font-weight: 400;
  line-height: 1.7;
  box-sizing: border-box;
}

.heading-primary {
  font-size: 5rem;
  font-weight: 700;
  margin-bottom: 3rem;
  line-height: normal;
  font-family: "Millik";
  @include respond(tab-port) {
    font-size: 3.5rem;
    margin-top: 4rem; // This is to give some space up for the search box to breath
  }
  @include respond(phone) {
    font-size: 3rem;
  }
}
.heading-secondary {
  font-size: 4rem;
  // letter-spacing: 0.2rem;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 2rem;
  font-family: "Millik";

  @include respond(tab-port) {
    font-size: 3rem;
  }
  @include respond(phone) {
    font-size: 2.5rem;
  }
}
.heading-tertiary {
  font-size: 3rem;
  font-weight: 700;
  font-family: "Millik";
  line-height: normal;
  @include respond(tab-port) {
    font-size: 2.5rem;
  }
  @include respond(phone) {
    font-size: 2rem;
  }
}

.paragraph-primary {
  font-size: $default-font-size;

  &:not(:last-child) {
    margin-bottom: 3rem;
  }
}
.paragraph-secondary {
  font-size: $secondary-font-size;

  &:not(:last-child) {
    margin-bottom: 3rem;
  }
}
