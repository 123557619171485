*,
*::after,
*::before {
  margin: 0px;
  padding: 0px;
  box-sizing: inherit;
}

html {
  /* Setting HTML font to 62.5% allows user to zoon in and out while giving us the 10px base from 16px default */
  /* Later allows for easier use of rem - note: rem not compatible with ES9 */
  // This defines what 1rem is
  font-size: 62.5%; //1rem = 10px 10px/16px = 62.5%

  @include respond(tab-land) {
    //width < 1200
    font-size: 56.25%; //1rem = 9px, 9/16 = 56.25%
  }
  @include respond(tab-port) {
    //width < 900
    font-size: 50%; //1rem = 8px, 8/18 = 50%
  }
  @include respond(big-desktop) {
    font-size: 75%; //1rem = 12, 12/16 = 75%
  }
}

body {
  box-sizing: border-box;
  width: 100%;

  @include respond(tab-port) {
    margin-top: 3rem;
  }

  @include respond(tab-port) {
    padding: 0;
  }
}

section {
  margin: 10rem 0;
  padding: 0 10vw;
  text-align: left;
  @include respond(tab-port) {
    padding: 0 5vw;
  }
}

header {
  padding: 0 10vw;
}

::selection {
  background-color: $color-primary;
  // color: $color-white;
}

ul {
  list-style-type: none;
}

.fl-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include respond(tab-port) {
    flex-direction: column;
    margin-bottom: 3rem;
  }
}

.fl-row-mb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include respond(tab-port) {
    margin-bottom: 3rem;
  }
}

.fl-col {
  display: flex;
  flex-direction: column;
}
// section with one of the children occupying 20% of the section
.wd-2 {
  width: 20%;

  @include respond(tab-port) {
    width: 100%;
    color: black;
  }
}

// section with one of the children occupying 30% of the section
.wd-3 {
  width: 30%;

  @include respond(tab-port) {
    width: 100%;
    color: black;
  }
}
// section with one of the children occupying 40% of the section
.wd-4 {
  width: 40%;

  @include respond(tab-port) {
    width: 100%;
    color: black;
  }
}
// section with one of the children occupying 40% of the section
.wd-45 {
  width: 45%;

  @include respond(tab-port) {
    width: 100%;
    color: black;
  }
}
// section with one of the children occupying 50% of the section
.wd-5 {
  width: 50%;

  @include respond(tab-port) {
    width: 100%;
    color: black;
  }
}
// section with one of the children occupying 60% of the section
.wd-6 {
  width: 60%;

  @include respond(tab-port) {
    width: 100%;
    color: black;
  }
}
// section with one of the children occupying 65% of the section
.wd-65 {
  width: 65%;

  @include respond(tab-port) {
    width: 100%;
    color: black;
  }
}
// section with one of the children occupying 70% of the section
.wd-7 {
  width: 70%;

  @include respond(tab-port) {
    width: 100%;
    color: black;
  }
}

// section with one of the children occupying 80% of the section
.wd-8 {
  width: 80%;

  @include respond(tab-port) {
    width: 100%;
    color: black;
  }
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
