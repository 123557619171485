.template {
  width: 70%;
  height: 40rem;
  box-shadow: $box-shadow-1;
  text-align: center;
  padding: 2rem;
  text-decoration: none;
  @include respond(tab-port) {
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  & span {
    display: block;
    height: 0.5rem;
    background: #ccc;
  }
  & .hd {
    width: 100%;
    margin-bottom: 2rem;
    &-1 {
      width: 80%;
      margin: auto;
    }
    &-2 {
      width: 70%;
      margin: 0.3rem auto;
    }
    &-3 {
      width: 60%;
      margin: auto;
    }
  }
  & .bd {
    width: 100%;
    margin-bottom: 2rem;
    &-cont {
      width: 100%;
      margin: 0.5rem 0;
    }
  }
  & .dp {
    width: 100%;
    margin: 4rem 0;
    &-rt {
      width: 40%;
      margin-left: 60%;
    }
  }
  & .ct {
    margin-bottom: 3rem;
    &-sw {
      width: 30%;
      margin-right: 60%;
      margin-bottom: 0.5rem;
    }
  }

  & .cm {
    margin-top: 5rem;
    &-1 {
      width: 30%;
      margin: 3rem auto;
      margin-bottom: 1rem;
    }
    &-2 {
      width: 50%;
      margin: 0 auto;
      margin-bottom: 0.3rem;
    }
  }
  & h3 {
    padding: 1rem 0;
    font-size: 2rem;
    font-weight: bold;
    line-height: normal;
  }
}
