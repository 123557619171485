.form-container {
  width: 100%;
  transition: transform 0.3s ease-in-out;
  margin-bottom: 1rem;
  & .question {
    display: block;
    width: 100%;
    min-width: auto;
    & input,
    & select {
      height: 4rem;
      width: 100%;
      border: none;
      background: #f2f2f2;
      margin-bottom: 1rem;
      border-radius: 1rem;
      padding: 0 1rem;
      & option {
        height: 3rem;
        background: #f8f8f8;
        padding: 2rem;
        &:nth-child(even) {
          background: $color-white;
        }
      }
    }
    & .date {
      display: flex;
      flex-direction: column;
      position: relative;

      & .calendar-icon {
        position: absolute;
        font-size: 2rem;
        color: orange;
        right: 1rem;
        top: 1rem;
      }
      &__input-hidden {
        width: 100%;
      }
      &__input-select {
        width: 100%;
        color: $color-white;
        & select {
          height: 4rem;
          width: 33%;
        }
      }
    }
  }
}

.label {
  display: block;
  margin-bottom: 1rem;
  font-size: 2rem;
}
.tip {
  font-size: 1.5rem;
  letter-spacing: 1px;
  font-style: italic;
  margin: 1rem 0 2rem 0;
  background: black;
  color: #fff;
  font-weight: bold;
  padding: 1rem;
}
.formated-text {
  color: $color-primary;
}
