.aff-wraper {
  height: 80vh;
  overflow-y: auto;
  border-bottom: 1px solid #ccc;
  @include respond(tab-port) {
    height: auto;
  }
}

.post-download {
  width: 60rem;
  margin: auto;
  text-align: left;
  margin-bottom: 2rem;
  margin-top: 2rem;
  @include respond(tab-port) {
    width: auto;
    padding: 6rem 5vw;
    padding-bottom: 1rem;
  }
  & h2 {
    padding: 1rem 0;
  }
  & h4 {
    font-style: italic;
    font-size: 1.5rem;
    font-weight: bold;
  }
}
.final {
  width: 600px;
  margin: auto;
  padding: 3rem 5rem;
  box-shadow: $box-shadow-1;
  @include respond(tab-port) {
    width: 95%;
    padding: 1rem;
    box-shadow: none;
    border: 1px solid #ccc;
  }
  &-head,
  &-title {
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    margin: 0;
    @include respond(tab-port) {
      font-size: 12px;
    }
  }
  &-title {
    margin: 2rem auto;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  &-list {
    display: flex;
    margin-bottom: 1rem;
    @include respond(tab-port) {
    }
    &__index {
      margin-right: 1.5rem;
      margin-left: 2.5rem;
      font-size: 13px;
    }
    &__text {
      line-height: 1.5;
      text-align: left;
      font-size: 13px;
      & .leftPadded {
        padding-left: 3rem !important;
        margin: 0rem !important;
      }
    }
  }
  & .leftPadded {
    padding-left: 1.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  &-footer {
    // margin-top: 6rem;
    text-transform: uppercase;
    font-size: 16px;
    @include respond(tab-port) {
      font-size: 12px;
    }
    & .deponent {
      display: flex;
      justify-content: flex-end;
      margin-top: 5rem;
      padding: 0;
      & p {
        display: inline-block;
        border-top: 1px solid black;
        padding: 0;
      }
    }
    & .court-section {
      & p {
        margin: 1rem 0;
        text-align: left;
      }
      & .court,
      & .terminal {
        font-weight: 700;
        text-align: center;
      }
      & .terminal {
        margin-top: 3rem;
      }
    }
  }
}
.download-aff {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 3rem;
  & button {
    letter-spacing: 3px;
  }
  & .review {
    color: black;
    font-weight: bold;
    border: 1px solid $color-primary;
    margin-left: 1rem;
  }
  & .close-review {
    color: red;
    font-weight: bold;
    border: 1px solid red;
    margin-left: 1rem;
  }
}
.review {
  padding: 3rem 10vw;
  text-align: center;
  @include respond(tab-port) {
    padding: 3rem 5vw;
  }
  & h4 {
    font-size: 2rem;
  }
  & p {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  & form input {
    width: 50rem;
    height: 10rem;
    border: none;
    padding: 2rem;
    &::placeholder {
      // color: $color-primary;
      font-size: 2rem;
      font-weight: bold;
    }
    &:focus {
      outline: none;
    }
  }
}
.flash {
  width: 50%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
  font-size: 2rem;
  background: $color-bg-main-2;
  @include respond(tab-port) {
    width: 100%;
  }
}
