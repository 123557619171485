.footer {
  width: 100%;
  color: $color-white;
  letter-spacing: 1px;
  background: $color-blue-dark;
  height: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 0 !important;
  @include respond(tab-port) {
    position: static;
  }
  & .text {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-size: 1.2rem;
    }
    a {
      font-size: 1.5rem;
      margin: 0 0.4rem;
      color: #666;
      text-decoration: none;
      &:hover {
        color: $color-white;
      }
    }
  }
  & .chat {
    text-align: right;
    position: fixed;
    top: 83%;
    right: 2rem;
    width: 8rem;
    height: auto;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1000;
    @include respond(tab-port) {
      right: 1rem;
      top: 85%;
      // display: none;
    }
    & .icon {
      font-size: 6rem;
      color: $color-primary;
      font-weight: bold;
      @include respond(tab-port) {
        font-size: 4rem;
      }
    }
  }
}
.chatBox {
  height: 30rem;
  width: 30rem;
  position: fixed;
  bottom: 10%;
  right: 2rem;
  background: $color-primary;
  color: $color-white;
  z-index: 1000;
  & .closeChat {
    display: flex;
    justify-content: flex-end;
    margin-right: 2rem;
    padding-top: 1rem;
    padding-right: 2rem;
    font-size: 2.5rem;
    cursor: pointer;
    :hover {
      color: red;
    }
  }
  h3 {
    font-size: 2rem;
  }
  .whatsapp {
    text-align: center;
    a {
      color: $color-white;
    }
    .icon {
      font-size: 4rem;
    }
  }
}
