.hm-container {
  background: $color-bg-main-2;
}
.hm-header {
  height: 100vh;
  @include respond(tab-port) {
    height: auto;
    margin-top: 0;
  }
}
.hm-hero {
  display: flex;
  height: 100%;
  align-items: center;
  @include respond(tab-port) {
    height: auto;
    margin-top: 0;
    padding-top: 6rem;
  }

  &__text {
    padding-right: 10rem;
    @include respond(tab-port) {
      width: 100%;
      padding-right: 0;
      & h1 {
        width: 100%;
        font-size: 4rem;
      }

      & p {
        margin-bottom: 0;
        font-size: 2rem;
      }
    }
    & span {
      font-style: italic;
      font-size: 1.5rem;
      font-weight: bold;
      color: $color-primary;
      cursor: pointer;
    }
    & .hero-cta {
      @include respond(tab-port) {
        & a {
          display: none;
        }
      }
    }
  }
  &__video {
    display: flex;
    justify-content: center;
    align-items: center;
    @include respond(tab-port) {
      width: 100%;
      justify-content: center;
    }
    & .cle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50rem;
      height: 50rem;
      border-radius: 50%;
      background: $color-primary;
      position: relative;
      & .template {
        width: 70%;
        height: 46rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: white;
        @include respond(tab-port) {
          width: 100%;
          height: 40rem;
          top: 45%;
        }
      }
      @include respond(tab-port) {
        width: 45rem;
        height: 45rem;
        background: none;
      }
    }
  }
}

.app-flow {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color-bg-main-1;
  padding-top: 10rem;
  padding-bottom: 10rem;
  margin-bottom: 0;
  @include respond(tab-port) {
    margin-top: 3rem;
  }
  &__inner {
    width: 70%;
    margin: auto;
    height: auto;
    border-radius: 5rem;
    background: $color-white;
    padding-bottom: 5rem;
    @include respond(tab-port) {
      width: 100%;
    }
    & h2 {
      // font-size: 4rem;
      padding: 5rem;
      text-align: center !important;
      line-height: normal;
      font-weight: bold;
      @include respond(tab-port) {
        // font-size: 2.5rem;
        line-height: 1.2;
      }
      & .tagline {
        font-size: 2rem;
        display: block;
      }
      & .outer {
        display: block;
        & span {
          font-size: 2rem;
          text-transform: capitalize;
          color: $color-primary;
          font-weight: bold;
          margin-left: 1.5rem;
          &::before {
            content: "\2022"; /* Unicode for bullet point (•) */
            display: inline-block;
            width: 1em;
            color: $color-orange;
          }
        }
      }
    }
    & .slide {
      display: flex;
      justify-content: space-between;
      height: auto;
      width: 80%;
      margin: auto;
      position: relative;
      // margin-bottom: 5rem;
      @include respond(tab-port) {
        width: 95%;
      }
      &__steps {
        width: 20%;

        & .inactive,
        & .active-link {
          height: 8rem;
          width: 8rem;
          border-radius: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background: $color-primary;
          color: $color-white;
          text-transform: capitalize;
          margin-bottom: 2rem;
          cursor: pointer;
          text-transform: capitalize;
          @include respond(phone) {
            height: 6rem;
            width: 6rem;
          }

          & .icon {
            font-size: 3rem;
          }
        }
        & .active-link {
          background: #b9ecb9;
        }
      }
      &__content {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        box-shadow: $box-shadow-2;
        margin-bottom: 1rem;
        box-shadow: none;

        & .item {
          display: none;
        }
        & .active {
          & h3 {
            font-size: 2.5rem;
            color: #999;
          }
          & ul li {
            font-size: 2rem;
          }
        }
        & .get-started {
          text-align: center;
          margin-top: 4rem;
          & a {
            font-size: 1.5rem;
            letter-spacing: 0.5rem;
          }
        }
      }
    }
  }
}

.home-doc {
  background: $color-white;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 10rem;
  padding-bottom: 10rem;
  position: relative;
  @include respond(tab-port) {
    padding-bottom: 20rem;
  }
  &__text {
    align-self: center;
    & h3 {
      font-weight: 700;
      & span {
        color: $color-primary;
        font-style: italic;
      }
      @include respond(tab-port) {
        width: 100%;
      }
    }
    & p {
      margin: 2rem 0;
      margin-bottom: 4rem;
      // font-size: 2rem;
    }
    @include respond(tab-port) {
      width: 100%;
      margin-bottom: 3rem;
    }
    & .cta {
      @include respond(tab-port) {
        position: absolute;
        bottom: 7rem;
        left: 35%;
      }
    }
  }
  &__img {
    text-align: center;
  }
}

.app-feature {
  padding-top: 10rem;
  padding-bottom: 10rem;
  margin-bottom: 0;
  margin-top: 0;
  display: flex;
  justify-content: space-between;
  background: $color-bg-main-1;
  @include respond(tab-port) {
    border-bottom-right-radius: 10rem;
    flex-direction: column-reverse;
  }
  & article {
    flex-basis: 48%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include respond(tab-port) {
      flex-basis: 100%;
    }
    & p {
      width: 80%;
      @include respond(tab-port) {
        width: 100%;
      }
    }
    & .get-started {
      margin-top: 3rem;
    }
    @include respond(tab-port) {
      width: 100%;
    }
  }
  & .circle-article {
    & .circle-image {
      position: relative;
      border-radius: 50%;
      width: 50rem;
      height: 50rem;
      background-color: $color-primary;
      background-image: url("../../../images/orders.svg");
      background-size: 28rem 45rem;
      background-position: center center;
      background-repeat: no-repeat;
      @include respond(tab-port) {
        width: 40rem;
        height: 40rem;
        background-size: 25rem 38rem;
      }
    }
  }
  // @include respond(tab-port) {
  //   flex-direction: column-reverse;
  // }
}

.review {
  margin-bottom: 0;
  height: auto;
  background: $color-bg-main-2;
  .title {
    text-align: center;
    margin-bottom: 2rem;
  }
  .paragraph-primary {
    width: 50%;
    margin: auto;
    font-size: 2rem;
    @include respond(tab-port) {
      width: 100%;
    }
  }
  .title span {
    font-size: 0.85em;
    color: $color-primary;
    margin-right: 1rem;
    font-weight: 700;
  }
  .review-text {
    margin: 0 auto;
    margin-top: 4rem;
    width: 80vw;
    height: 450px;
    max-width: 800px;
    text-align: center;
    position: relative;
    display: flex;
    & .review {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    & .coment {
      max-width: 35em;
      margin: 0 auto;
      margin-top: 2rem;
      line-height: 2;
      color: grey;
    }
    & .icon {
      font-size: 8rem;
      margin-top: 1rem;
      color: $color-primary;
    }
  }

  .prev,
  .next {
    position: absolute;
    top: 200px;
    transform: translateY(-50%);
    background: $color-primary;
    color: $color-white;
    width: 30px;
    height: 30px;
    display: grid;
    place-items: center;
    border-color: transparent;
    font-size: 3rem;
    border-radius: 1rem;
    cursor: pointer;
    transition: all 0.3s linear;
  }
  .prev:hover,
  .next:hover {
    background: grey;
  }
  .prev {
    left: 0;
  }
  .next {
    right: 0;
  }
  @include respond(tab-port) {
    .text {
      max-width: 45em;
    }
    .prev,
    .next {
      width: 2rem;
      height: 2rem;
      top: 90%;
      // font-size: 1.5rem;
    }
  }
  article {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.3s linear;
  }
  article.activeSlide {
    opacity: 1;
    transform: translateX(0);
  }
  article.lastSlide {
    transform: translateX(-100%);
  }
  article.nextSlide {
    transform: translateX(100%);
  }
}

.in-house {
  margin-top: 20rem;
  @include respond(tab-port) {
    margin-top: 10rem;
  }
  &__inner {
    width: 70%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $color-bg-main-1;
    border-radius: 5rem;
    padding: 5rem 0;
    @include respond(tab-port) {
      width: 100%;
      padding: 3rem 2rem;
    }
    &-text {
      width: 70%;
      @include respond(tab-port) {
        width: 100%;
        padding: 5rem 0;
      }
      & h3 {
        font-size: 3rem;
        padding: 1rem 0;
        @include respond(tab-port) {
          font-size: 2.5rem;
        }
      }
      & p {
        margin-bottom: 3rem;
      }
    }
    & .get-started {
      margin-top: 4rem;
    }
  }
}
.search-popup {
  width: 50%;
  background: $color-white;
  padding: 3rem;
  margin-top: -15rem;
  @include respond(phone) {
    width: 90%;
    padding: 1rem;
    padding-bottom: 3rem;
  }
  & .close-search {
    text-align: right;
    & button {
      background: none;
      font-weight: bold;
      border: none;
      font-size: 3rem;
      cursor: pointer;
      &:hover {
        font-weight: normal;
      }
    }
  }
}
