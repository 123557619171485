.chart {
  padding: 3rem 0;
  & .close-box {
    margin: 2rem 0;
    height: 4rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    & button {
      background: none;
      border: none;
      font-weight: bold;
      font-size: 3rem;
      text-transform: uppercase;
      cursor: pointer;
      &:hover {
        color: red;
      }
    }
  }
  & h2 {
    font-size: 2rem;
    line-height: normal;
    font-weight: bold;
  }
  & h3,
  & .emp {
    font-size: 2rem;
    font-style: italic;
    color: orange;
    padding: 1rem 0;
    display: block;
  }
  & ul {
    padding: 2rem 0;
    & li {
      display: flex;
      align-items: center;
      padding: 1rem 0;
      & .icon-box {
        & .icon {
          color: orange;
          font-size: 3rem;
          margin-right: 1rem;
        }
      }
      & .text {
        font-size: 2rem;
      }
    }
  }
  & .cta {
    margin-top: 5rem;
  }
}
