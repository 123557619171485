.support {
  width: 60%;
  height: 100vh;
  margin: 5rem auto;
  margin-bottom: 0;
  & input,
  & select,
  & textarea {
    width: 100%;
    margin: 2rem 0;
    padding: 1rem;
  }
  & input,
  & select {
    height: 4rem;
  }
  & textarea {
    height: 20rem;
  }
  @include respond(tab-port) {
    width: 100%;
    padding-top: 8rem;
    overflow-y: auto;
  }
}
