.header-container {
  width: 100%;
  display: flex;
  @include respond(tab-port) {
    flex-direction: row;
    margin-top: 10rem;
    padding-left: 0 !important;
    margin-right: 0 !important;
  }
  & .btn,
  & .text {
    display: flex;
    align-items: center;
    padding: 2rem 0;
  }
  & .btn {
    width: 20%;
    display: none;
    cursor: pointer;
    background: none;
    & .icon {
      font-size: 3rem;
      color: $color-black;
    }
    @include respond(tab-port) {
      margin-left: 0;
      display: flex;
    }
  }
  & .text {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    & h1 {
      text-align: center;
      font-size: 2rem;
    }
  }
}
