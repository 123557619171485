.question {
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
  box-shadow: $box-shadow-2;
}
.question div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.question div h4 {
  margin-bottom: 0;
  text-transform: none;
  line-height: 1.2;
  font-size: 1.8rem;
  font-weight: normal;
}
.question p {
  color: $color-grey-dark-3;
  margin-bottom: 0;
  margin-top: 0.5rem;
  font-size: 1.6rem;
}
.btn {
  background: transparent;
  border-color: transparent;
  width: 2rem;
  height: 2rem;
  background: $color-primary;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: $color-white;
  cursor: pointer;
  margin-left: 1rem;
  align-self: center;
  min-width: 2rem;
}
