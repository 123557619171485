.result {
  width: 50%;
  height: 60rem;
  margin: auto;
  overflow: scroll;
  padding: 0 3rem;
  position: absolute;
  background: $color-white;
  left: 25%;
  display: relative;
  @include respond(tab-port) {
    width: 95%;
    left: 2.5%;
    padding: 2rem 1rem;
  }
  &-head,
  &-title {
    font-weight: 700;
    font-size: 2rem;
    margin-bottom: 4rem;
    text-align: center;
    line-height: 1.5;
    @include respond(tab-port) {
      font-size: 2rem;
    }
  }
  &-head {
    padding-top: 2rem;
  }
  &-title {
    text-transform: uppercase;
  }
  &-list {
    margin: 2rem !important;
    display: flex;
    @include respond(tab-port) {
    }
    &__index {
      margin-right: 1.5rem;
      margin-left: 2.5rem;
      font-size: 2rem;
    }
    &__text {
      line-height: 1.5;
      & .leftPadded {
        padding-left: 3rem !important;
        margin: 0rem !important;
      }
    }
  }

  &-footer {
    margin-top: 6rem;
    text-transform: uppercase;
    font-size: 2rem;
    @include respond(tab-port) {
      font-size: 1.5rem;
    }
    & .deponent {
      display: flex;
      justify-content: flex-end;
      margin: 4rem 0;
      & p {
        display: inline-block;
        border-top: 1px solid black;
      }
    }
    & .court-section {
      & p {
        margin: 2rem 0;
        text-transform: uppercase;
      }
      & .court,
      & .terminal {
        font-weight: 700;
        text-align: center;
      }
      & .terminal {
        margin-top: 3.5rem;
      }
    }
  }
}
.search {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
