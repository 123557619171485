// COLORS
$color-blue-dark: #010516;
$color-account: #edeeee;
$color-success: #06f212;

$color-secondary-light: #ffb900;
$color-secondary-dark: #ff7730;

$color-bg-main-1: #f4f6f6;
$color-bg-main-2: #f9fafa;

$box-shadow-1: rgba(0, 0, 0, 0.35) 0px 5px 15px;
$box-shadow-2: rgba(0, 0, 0, 0.16) 0px 1px 4px;

$color-white: #fff;
$color-black: #000;
$color-primary: #34ab56;
$color-orange: #ffa500;
$color-red: red;
$color-grey-dark-3: #333;
$color-grey-light-4: #444;

$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-blue-light: #d9d9ed;

// FONT
$default-font-size: 1.8rem;
$secondary-font-size: 1.2rem;
