.form__input{
    width: 100%;
}
.passwd-hints{
    padding: 2rem 0;
    & h4{
        font-size: 1.5rem;
    }
    & p{
        font-size: 1.2rem;
    }
}