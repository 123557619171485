.dashboard {
  padding-bottom: 3rem;
  border-bottom: 0.5rem solid $color-bg-main-1;
  & .btn-action {
    & a {
      margin-right: 1rem;
    }
    & button {
      cursor: pointer;
      &:hover {
        color: $color-red;
      }
    }
  }
}
