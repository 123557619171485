.aff-category {
  height: 100vh;
  overflow-y: auto;
  &-inner {
    &__item {
      width: 100%;
      :first-of-type {
        padding-right: 3rem;
        @include respond(tab-port) {
          padding-right: 0;
        }
      }
    }
    &__item--2 {
      display: flex;
      flex-direction: column;
      width: 100%;
      @include respond(tab-port) {
        margin-top: 5rem;
      }
      & .cta {
        text-align: center;
        text-transform: capitalize;
      }
    }
  }
}
.not-ready {
  @include respond(tab-port) {
    flex-direction: column;
  }
  &__text {
    & .heading-tertiary {
      @include respond(phone) {
        line-height: 1.5;
      }
    }
    @include respond(tab-port) {
      width: 100%;
    }
    & p {
      padding: 1rem 0;
    }
    & ul {
      margin-bottom: 2rem;
      & li {
        display: flex;
        align-items: center;
        font-size: 2rem;
        line-height: normal;
        padding: 1rem 0;
      }
      & .icon {
        background: #d2ebd2;
        margin-right: 1rem;
      }
    }
  }
  &__card {
    display: flex;
    justify-content: flex-end;
    @include respond(tab-port) {
      width: 100%;
      justify-content: center;
      margin-top: 2rem;
    }
  }
}
