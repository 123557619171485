.account-container {
  position: relative;
  max-height: 100vh;
  margin-top: 0;
  margin-bottom: 0;
  z-index: 0;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    // background: $color-account;
  }
  & .account {
    display: flex;
    flex-direction: column;
    padding-top: 0;
    align-items: center;
    width: 100%;
    z-index: 0;
    // &::before {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   bottom: 0;
    //   z-index: -1;
    //   background: $color-bg-main-2;
    //   -webkit-clip-path: polygon(0 22%, 0 0, 100% 0, 100% 18%, 50% 46%);
    //   clip-path: polygon(0 22%, 0 0, 100% 0, 100% 18%, 50% 46%);
    // }
    & .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 45%;
      margin-top: 5rem;
      box-shadow: $box-shadow-1;
      border-radius: 1.5rem;
      @include respond(tab-port) {
        box-shadow: none;
      }

      @include respond(tab-port) {
        width: 90%;
      }
      @include respond(phone) {
        width: 95%;
      }
      & .social {
        padding-bottom: 1rem;
        text-align: center;
        width: 70%;
        & .platforms {
          width: 100%;
          padding: 2rem 1rem;
          & .btn {
            display: flex;
            width: 48%;
            background: none;
            border: 1px solid blue;
            padding: 2rem 0;
            border-radius: 1rem;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            @include respond(phone) {
              width: 100%;
            }
            & .btn-text {
              color: #333;
            }
            &-google,
            &-facebook {
              & .icon {
                font-size: 3rem;
                margin-left: 0;
              }
            }
            &-google {
              border: 1px solid green;
              @include respond(tab-port) {
                margin-bottom: 2rem;
              }
            }
          }
        }
      }
      & .divider {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        & span {
          font-size: 3rem;
          font-weight: bold;
        }
      }
      & .credential {
        width: 100%;
        margin-top: 2rem;
        padding: 2rem 0;
        // background: $color-bg-main-2;
        & .form {
          width: 70%;
          margin: auto;
          @include respond(phone) {
            width: 90%;
          }
        }

        & h3 {
          font-size: 2rem;
          font-weight: 700;
          text-align: center;
          line-height: normal;
          margin-bottom: 1rem;
        }
        & .account-message {
          background: #dae4e4;
          font-size: 1.5rem;
          padding: 0.5rem;
          margin-bottom: 2rem;
          border-radius: 1rem;
          & .message {
            line-height: normal;
          }
        }

        & input {
          background: $color-white;
          border-radius: 1rem;
          height: 4rem;
          border: 1px solid #ccc;
        }

        & .btn-action {
          width: 100%;
          text-align: center;
          border-radius: 1rem;
          border: none;
          & .cta {
            width: 100%;
          }
        }
        .disabled {
          background: #ccc;
          height: 4rem;
        }
      }
      & .reset {
        padding: 1rem 0;
        & .pwd-reset {
          font-size: 1.8rem;
          text-decoration: none;
          color: #333;
        }
      }
      & .member {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem 0;
        background: $color-bg-main-2;
        & .text {
          font-size: 1.8rem;
          font-weight: bold;
        }
        & button {
          cursor: pointer;
          border: none;
          background: none;
          margin-left: 1rem;
        }
        & .join {
          font-size: 1.8rem;
        }
      }
    }
  }
}
