.search-container {
  padding: 2rem 0;
  width: 100%;
  position: relative;

  & .search-form {
    width: 100%;
    position: relative;
    height: 3.5rem;

    & input {
      width: 100%;
      height: 3.5rem;
      border-radius: 2rem;
      text-align: center;
      border: none;
      background-color: #f8f8f8;
      &::placeholder {
        color: $color-primary;
        color: #010516;
        font-size: 1.8rem;
        letter-spacing: 1.5px;
      }
      &:focus {
        outline: none;
        box-shadow: 5px 5px 4px #888888;
      }
      @include respond(tab-port) {
        height: 6rem;
        border: 3px solid #ccc;
      }
    }
    & .btn {
      position: absolute;
      top: 0.9rem;
      cursor: pointer;
      @include respond(tab-port) {
        top: 2rem;
      }
    }
    & .search-btn {
      left: 2rem;
      @include respond(phone) {
        left: 1rem;
      }
    }
    & .clear-btn {
      right: 2rem;
      @include respond(phone) {
        right: 1rem;
      }
    }
  }
  & .result {
    margin-top: 2px !important;
    height: 40rem !important;
    padding: 0 !important;
    padding-top: 3rem !important;
    background-color: $color-white;
    text-align: left;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    &::-webkit-scrollbar {
      display: none;
    }
    z-index: 100;
    display: relative;
    @include respond(tab-port) {
      margin-top: 23px !important;
    }
    & .close {
      display: absolute;
      left: 0;
      top: 0;
      width: 100% !important;
      margin-bottom: 2rem;
      &__btn {
        border: none;
        background: none;
        font-size: 2rem;
        cursor: pointer;
        position: absolute;
        right: 1rem;
        top: 0.5rem;
      }
    }
    & .item {
      width: 100%;
      height: 4rem;
      display: flex;
      align-items: center;
      background-color: $color-bg-main-2;
      margin-top: 0.5rem;

      & a {
        text-decoration: none;
        width: 100%;
        padding: 1rem;
        color: $color-black;
        font-size: 1.5rem;
        line-height: normal;

        &:hover {
          background-color: lightgrey;
        }
      }
    }
  }
}
