.not-found {
  justify-content: center;
  &__content {
    width: 100%;
    margin-top: 0rem;
    margin: auto;
    display: flex;
    // align-items: center;
    @include respond(tab-port) {
      width: 100%;
      flex-direction: column;
    }
    &--left {
      width: 50%;
      @include respond(tab-port) {
        width: 100%;
        margin-bottom: 3rem;
      }
      & h1 {
        font-size: 3rem;
        line-height: normal;
        padding: 0;
      }
      & .img {
        width: 100%;
        margin: 3rem 0;
        justify-content: center;
        align-items: center;
        & p {
          font-size: 1.8rem;
          width: 100%;
          align-self: center;
          @include respond(tab-port) {
            width: 100%;
            color: #0e0820;
          }
        }
      }
      & .btn {
        width: 100%;
        display: flex;
        justify-content: center;
        & button {
          margin-top: 2rem;
          font-size: 2rem;
          font-weight: bold;
          & a {
            text-decoration: none;
          }
        }
      }
    }
    &--right {
      width: 50%;
      padding-left: 3rem;
      @include respond(tab-port) {
        width: 100%;
      }
      & h3 {
        font-size: 2.5rem;
      }
      & ul li {
        font-size: 1.8rem;
        margin: 0.5rem 0;
        list-style: disc;
      }
    }
  }
}
