.auth-home {
  height: calc(100vh - 16rem);
  overflow-y: auto;
}
.user-header {
  width: 100%;
  background: $color-bg-main-1;
  @include respond(tab-port) {
    padding: 0 5vw;
  }
}
.user-content {
  width: 100%;
  height: auto;
  position: relative;
  background: $color-bg-main-2;
  margin-top: 0;
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin-bottom: 0;

  &__link {
    width: 20% !important;
    background: $color-white;
    padding: 3rem;
    box-shadow: $box-shadow-2;
    @include respond(tab-port) {
      display: none;
      width: 100% !important;
    }
  }
  &__item {
    width: 70% !important;
    & h2 {
      padding: 1rem 0;
      font-size: 2rem;
      line-height: 1.2;
    }
    & h3 {
      padding: 1rem 0;
      font-size: 2rem;
      line-height: 1.2;
    }
    & h4 {
      font-size: 1.5rem;
      font-style: italic;
    }
    & .order-container {
      flex-wrap: wrap;
      margin-bottom: 5rem;
    }
    @include respond(tab-port) {
      flex-direction: column;
      width: 100% !important;
    }
    & .profile {
      width: 100%;
      padding: 5rem 0;
      border-bottom: 1px solid #ccc;
      & h3 {
        font-size: 2rem;
        padding: 2rem 0;
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
  @include respond(tab-port) {
    width: 100%;
    // If the 'flex-direction' property is turned on, the menu will appears on the same horizontal axis with the adjecent content
    // flex-direction: row;
  }
}
// toggling the menu hambugger
.menu-open {
  display: flex;
  width: 100%;
  z-index: 100;
}
