.sidebar {
  position: relative;
  padding: 0 !important;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 90%;
  height: 100%;
  transition: transform 0.3s ease-out;
  display: none;
  background: $color-blue-dark;
  color: $color-white;
  @include respond(tab-port) {
    display: flex;
  }
  & .list-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 2;
    height: 100%;
    width: 80%;
    padding-left: 2rem;
    & .sidebar-list {
      padding: 2rem 0;
      &__item {
        display: flex;
        align-items: center;
        color: $color-white;
        font-size: 2.5rem;
        text-decoration: none;
        padding: 2rem 0;
        border-bottom: 1px solid $color-white;
        & .icon {
          font-size: 3rem;
          font-weight: 700;
          color: $color-grey-dark-3;
          margin-right: 2rem;
        }
        &:hover {
          background: $color-bg-main-2;
        }
      }
    }
  }
  & .fx-links {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(243, 223, 223);
    position: absolute;
    left: 0;
    bottom: 0;
    width: 80%;
    height: 6rem;
    text-align: center;
    & .free-quote,
    & .account {
      width: 50%;
      height: 100%;
      text-decoration: none;
      padding: 1rem 2rem;
      font-size: 2rem;
      background: none;
      border: none;
      &:hover {
        background: $color-bg-main-2;
      }
    }
    & .free-quote {
      border-right: 1px solid $color-bg-main-2;
    }
  }
  &--open {
    transform: translateX(0);
  }

  &--closed {
    transform: translateX(-100%);
  }
}
