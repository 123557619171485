.disable {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: calc(100vh - 16rem);
  margin: auto;
  @include respond(tab-port) {
    width: 100%;
  }

  & h4 {
    font-size: 2.5rem;
    font-weight: bold;
  }
  & ul {
    list-style: circle;
    margin-left: 3rem;
    & li {
      font-size: 2rem;
      margin: 0.5rem;
    }
  }

  & small {
    font-size: 1.5rem;
    font-style: italic;
  }
  & .cta {
    display: inline-block;
    font-style: normal;
    padding: 1rem;
    margin-top: 2rem;
  }
}
