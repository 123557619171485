.animated-element {
  opacity: 0;
  -webkit-animation: fadeInOut 4s infinite; /* Safari and Chrome */
  -moz-animation: fadeInOut 4s infinite; /* Firefox */
  -o-animation: fadeInOut 4s infinite; /* Opera */
  animation: fadeInOut 4s infinite;
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-moz-keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-o-keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.animated-form {
  opacity: 0;
  -webkit-animation: fadeInOut 3s 1 forwards; /* Safari and Chrome */
  -moz-animation: fadeInOut 3s 1 forwards; /* Firefox */
  -o-animation: fadeInOut 3s 1 forwards; /* Opera */
  animation: fadeInOut 3s 1 forwards;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
