.document {
  display: flex;
  justify-content: space-between;
  background: $color-bg-main-1;
  height: 85vh;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 5rem;
  padding-bottom: 5rem;
  @include respond(tab-port) {
    margin-top: 8rem;
  }
  &__text {
    padding-right: 3rem;
    @include respond(tab-port) {
      padding-right: 0rem;
    }
    &--header {
      & h1 {
        line-height: normal;
        @include respond(tab-port) {
          line-height: 1.5;
        }
        & span {
          color: green;
          line-height: normal;
        }
      }
    }
    &--steps {
      margin-top: 3rem;
      & ul {
        & li {
          display: flex;
          align-items: center;
          font-size: 2rem;
          line-height: normal;
          padding: 1rem 0;
        }
        & .icon {
          background: #d2ebd2;
          margin-right: 1rem;
        }
        & h3 {
          font-style: italic;
          font-size: 3rem;
          font-weight: "bold";
          color: "green";
        }
      }
    }

    @include respond(tab-port) {
      margin-bottom: 3rem;
    }
  }
  &__content {
    margin: auto;
    // background: #d2ebd2;
    border-radius: 3rem;
    padding: 2rem 0;
    text-align: center;
    @include respond(tab-port) {
      width: 100%;
    }
    & .icon {
      font-size: 5rem;
      // margin-bottom: 2rem;
      color: $color-primary;
    }
    & .header {
      margin-bottom: 3rem;
      text-align: center;
    }
    &-form {
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;
      & .form-input {
        margin-bottom: 2rem;
      }
      & label {
        display: block;
        font-size: 1.5rem;
        padding: 0.5rem 0;
      }
      & textarea {
        width: 40rem;
        height: 20rem;
        padding: 2rem;
        border: none;
        display: block;
        @include respond(tab-port) {
          width: 38rem;
        }
      }
      & select {
        width: 40rem;
        height: 4rem;
        padding-left: 2rem;
        border: none;
        display: block;
        @include respond(tab-port) {
          width: 38rem;
        }
      }

      .disable {
        width: auto;
        height: 4rem;
        // margin: 2rem 0;
        // display: block;
        // background: green;
        background: #999;
        color: $color-white;
        // border-radius: 1rem;
        border: none;
        cursor: none;
      }

      & p {
        font-size: 1.5rem;
        font-style: italic;
        margin: 2rem 0;
        margin-left: 2rem;
      }
    }
  }
  @include respond(tab-port) {
    flex-direction: column;
    height: auto;
  }
}
.chat {
  background: $color-bg-main-1;
  width: 100%;
  height: 85vh;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 5rem;
  padding-bottom: 5rem;
  & .document__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
    & h1 {
      font-size: 2rem;
      margin: 2rem 0;
    }
    & p {
      font-size: 1.6rem;
      // margin: 2rem 0;
    }
    & .whatsapp-icon {
      font-size: 8rem;
      color: green;
      cursor: pointer;
    }
  }
}
