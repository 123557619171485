.order {
  width: 25rem;
  height: 20rem;
  border: 1px solid #ccc;
  border-radius: 1rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &__content {
    width: 85%;
    & h3 {
      font-size: 2rem;
      padding: 1.5rem 0;
    }
    & button {
      color: $color-white;
      margin-top: 2rem;
    }
  }
  @include respond(tab-port) {
    width: 100%;
  }
}
