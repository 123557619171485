.message {
  background: #dae4e4;
  font-size: 1.5rem;
  padding: 2rem;
  // text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  & .icon {
    color: inherit;
    margin-left: 2rem;
    font-size: 4rem;
  }

  @include respond(tab-port) {
    padding: 3rem 2rem;
    text-align: left;
  }
}
.error {
  color: $color-red;
}
.success {
  color: $color-primary;
}
.list-container {
  display: flex;
  margin-bottom: 1.5rem;
  & .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background: #dae4e4;
    margin-right: 1rem;
    color: $color-black;
  }
  & .list {
    font-size: $default-font-size;
    list-style: none;
  }
}
