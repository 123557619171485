.aff {
  padding: 0;
  min-height: 100vh;
  overflow-y: auto;

  @include respond(tab-port) {
    // height: auto;
    // border: 1px solid blue;
  }

  &-section {
    margin-top: 10rem;

    &__item {
      display: flex;

      // available in aff-section__item--2

      &--1 {
        margin-bottom: 3rem !important;
        @include respond(tab-port) {
          display: none;
        }
      }
      &--2 {
        flex-direction: column;
        justify-content: flex-start;
        padding-bottom: 3rem;

        @include respond(tab-port) {
          margin-top: 3rem;
        }
        & h3 {
          padding: 2rem 0;
        }

        & .action {
          margin-top: 2rem;
        }

        // available in aff-section__item--2
        &-inner {
          width: 100%;
          margin-top: 2rem;
          & .heading-tertiary {
            margin-bottom: 2rem;
            & span {
              font-size: 2rem;
              display: block;
              padding: 1rem 0;
            }
          }
        }
      }
    }
  }
}

.auth {
  width: 100%;
  height: calc(100vh - 16rem);
  display: flex;
  justify-content: center;
  align-items: center;
  @include respond(tab-port) {
    margin-top: 12rem;
  }
}

.unlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #010516;
  color: $color-white;
  padding: 1rem 6rem;
  border: 1px solid $color-primary;
  border-radius: 1rem;
  position: fixed;
  width: 48%;
  height: 15%;
  height: auto;
  padding: 5%;
  top: 45%;
  left: 25.5%;
  z-index: 1000;
  & .cta {
    margin-top: 2rem;
    font-size: 2rem;
    color: $color-primary;
    font-weight: 700;
    cursor: pointer;
    &:hover {
      border: 1px solid $color-white;
    }
  }
  @include respond(tab-port) {
    width: 90%;
    left: 5%;
    top: 60%;
    height: 25%;
  }
}
.position {
  margin-top: -20rem;
}
